import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerSatisfaction from "components/ui/extended/RegisterForCustomerSatisfaction";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import ReadPost from "components/ui/base/ReadPost";
import NumberSteps from "components/ui/base/NumberSteps";
import TestimonialWithImageYari from "components/ui/extended/TestimonialWithImageYari";
import NpsWidget from "components/ui/extended/NpsWidget";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import QuestionsAndAnswers from "../../components/ui/base/QuestionsAndAnswers";

interface NPSPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: NPSPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.net-promoter-score.seo.title")}
        description={t("pages.features.net-promoter-score.seo.description")}
        image={{
          relativePath:
            "meta/nps-survey-net-promoter-score-customer-satisfaction.jpg",
          alt: "NPS - Net Promoter Score Survey",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.net-promoter-score.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.net-promoter-score.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3, 4, 5].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.features.net-promoter-score.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.features.net-promoter-score.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.features.net-promoter-score.title")}
        description={t("pages.features.net-promoter-score.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={600}
        descriptionMaxWidth={600}
        image={{
          relativePath:
            "pages/features/net-promoter-score/nps-promoter-detractor-neutral.png",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Net Promoter Score",
            path: "/features/net-promoter-score/",
          },
        ]}
      />

      <ReadPost
        title={"6 Essential Customer Satisfaction Metrics to Track"}
        path={
          "/blog/customer-satisfaction/2020/12/14/6-essential-customer-satisfaction-metrics-to-track/"
        }
      />

      <NumberSteps
        title={t("pages.features.net-promoter-score.steps.title")}
        elements={[1, 2, 3, 4].map((index) => {
          return {
            title: t(
              `pages.features.net-promoter-score.steps.elements.${index}.title`
            ),
            description: t(
              `pages.features.net-promoter-score.steps.elements.${index}.description`
            ),
          };
        })}
      />

      <ImageWithDescription
        id={"advanced-segmentation"}
        title={t("pages.features.net-promoter-score.1.title")}
        description={t("pages.features.net-promoter-score.1.subtitle")}
        image={{
          relativePath:
            "pages/features/net-promoter-score/nps-survey-via-email-net-promoter-score.jpg",
          alt: t("pages.features.net-promoter-score.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"filter-reporting"}
        title={t("pages.features.net-promoter-score.2.title")}
        description={t("pages.features.net-promoter-score.2.subtitle")}
        image={{
          relativePath:
            "pages/features/net-promoter-score/nps-survey-customer-segmentation-nps-promoter-score.jpg",
          alt: t("pages.features.net-promoter-score.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <TestimonialWithImageYari />

      <ImageWithDescription
        id={"quick-look"}
        title={t("pages.features.net-promoter-score.3.title")}
        description={t("pages.features.net-promoter-score.3.subtitle")}
        image={{
          relativePath:
            "pages/features/net-promoter-score/nps-survey-net-promoter-score-individual-votes.jpg",
          alt: t("pages.features.net-promoter-score.3.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"additional-feedback"}
        title={t("pages.features.net-promoter-score.4.title")}
        description={t("pages.features.net-promoter-score.4.subtitle")}
        image={{
          relativePath:
            "pages/features/net-promoter-score/nps-survey-additional-feedback-promoter-score.jpg",
          alt: t("pages.features.net-promoter-score.4.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <NpsWidget />

      <FeatureGrid
        title={t("pages.features.net-promoter-score.others.title")}
        centered={false}
        sections={[
          {
            title: t(
              "pages.features.net-promoter-score.others.helpCenter.title"
            ),
            description: t(
              "pages.features.net-promoter-score.others.helpCenter.description"
            ),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t(
                  "pages.features.net-promoter-score.others.helpCenter.cta"
                ),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t("pages.features.net-promoter-score.others.surveys.title"),
            description: t(
              "pages.features.net-promoter-score.others.surveys.description"
            ),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.features.net-promoter-score.others.surveys.cta"),
                path: "/features/surveys/",
              },
            ],
          },
          {
            title: t("pages.features.net-promoter-score.others.funnels.title"),
            description: t(
              "pages.features.net-promoter-score.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.features.net-promoter-score.others.funnels.cta"),
                path: "/features/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <RegisterForCustomerSatisfaction />

      <Customers />

      <QuestionsAndAnswers
        title={t("pages.features.net-promoter-score.faq.title")}
        description={t("pages.features.net-promoter-score.faq.description")}
        elements={[1, 2, 3, 4, 5].map((index) => {
          return {
            question: t(
              `pages.features.net-promoter-score.faq.${index}.question`
            ),
            answer: t(`pages.features.net-promoter-score.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const npsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: {
        eq: "meta/nps-survey-net-promoter-score-customer-satisfaction.jpg"
      }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
